import React from 'react';
import {Pressable, View, Image, TextInput} from 'react-native';
import {color, style} from '../../Styles/Style.js';

// Accept and Cancel Buttons Icons //
const acceptIcon = require('../../assets/Images/UnoAccept.png');
const cancelIcon = require('../../assets/Images/UnoClose.png');

// Start Component //
const SubMenuItemEdit = (props) => {

    return (
        <View style={style.editItemContainer}>
            <TextInput
                name={props.name}
                autoFocus={true}
                placeholder={props.placeholder}
                placeholderTextColor={color.darkBlue60}
                style={style.editTextInput}
                onBlur={props.focusFalse}
                onChangeText={props.onChangeText}
                value={props.value}/>
            <View style={style.inputButtonsContainer}>
                <Pressable 
                    style={style.inputButton}
                    onPress={props.handlePressOk}>
                    <Image
                        style={style.inputIcon}
                        source={acceptIcon}/>
                </Pressable>
                <Pressable 
                    style={style.inputButton}
                    onPress={props.handlePressCancel}>
                    <Image
                        style={style.inputIcon}
                        source={cancelIcon}/>
                </Pressable>
            </View>
        </View>
    );
};

export default SubMenuItemEdit;
