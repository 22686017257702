import React, {useState} from 'react';
import {Pressable, View, Text, Image, TextInput} from 'react-native';
import {color, style} from '../../Styles/Style.js';
import {xanoRequestAccess} from '../../Utilities/Apis.js';
import secureLocalStorage from 'react-secure-storage';

// Send Icon //
const sendIcon = require('../../assets/Images/UnoSend.png');

// Start Component //
const EmailInput = (props) => {
    const [inputText, setInputText] = useState("")

    // Handling signup / login //
    const handleSend = async () => {
        if (!inputText) {
            return;
        };
        const isUser = await xanoRequestAccess(inputText);
        secureLocalStorage.setItem("isUser", isUser);
        props.setOnboarding(inputText);
        setInputText("");
    };

    return (
        <View style={style.instructContainer}>
            <View style={style.instructTextView}>
                <Text style={style.instructTitle}>Enter your email below to start talking with Uno</Text>
            </View>
            <View style={style.emailInputView}>
                <TextInput
                    style={style.emailTextInput}
                    onChangeText={setInputText}
                    placeholder='Type your email'
                    placeholderTextColor={color.darkBlue40}
                    value={inputText}>
                </TextInput>
                <Pressable style={style.loginSendButton}
                    onPress={handleSend}>
                    <Image
                        style={style.icon16}
                        source={sendIcon}/>
                </Pressable>
            </View>
        </View>
    );
};

export default EmailInput;
