import React from 'react';
import {View, Text, Image} from 'react-native';
import {style} from '../../Styles/Style.js';
import * as Animatable from 'react-native-animatable';

// Uno Logo Image //
const unoLogo = require('../../assets/Images/UnoLogo.png')

// WaveformRest Parameters //
const startSize = 260; 
const endSize = 280;
const breathDuration = 3800;

// WaveformActive Parameters //
const growDuration= 1800;
const grow = 340;
const delay1 = 600, delay2 = 1200, delay3 = 1800;
const growEasing = "linear";

// Breathing animation //
const breath = {
    from: {
        width: startSize, 
        height: startSize
    },
    to: {
        width: endSize, 
        height: endSize
    }
};

// Active animation //
const sonar = {
    from: {
        width: startSize, 
        height: startSize,
        borderWidth: 6,
        borderRadius: 80,
        opacity: 0.5
    },
    to: {
        width: grow, 
        height: grow,
        borderWidth: 40,
        borderRadius: 120,
        opacity: 0
    }
};

// Start Export Component //
export const WaveformRest = (props) => {

    return (
        <Animatable.View 
            style={style.restCircle} 
            animation={breath} 
            easing="ease-in-out-sine" 
            duration={breathDuration} 
            iterationCount="infinite" 
            direction='alternate'> 
            <View style={style.circleDim}/>
            {!props.welcome && !props.confirm?
                <Text style={style.textStatus}>{props.textStatus}</Text>
            : null}
            {props.welcome? 
                <View>
                    <Image
                        style={style.unoLogo}
                        source={unoLogo}/>
                    <Text style={style.textWelcome}>{props.welcomeSubtitle}</Text>
                </View>
            : null}
            {props.confirm?
                <Text style={style.textVolume}>{props.isUser? "Hey, Welcome back!" : "Glad to meet you!"}</Text> 
            : null}
        </Animatable.View>
    );
};

// Start Export Component //
export const WaveformProcess = (props) => {

    return (
        <View style={style.animatedWaveformContainer}>
            <Animatable.View 
                style={style.unoSquare} 
                animation="rotate"
                easing="linear"
                duration={3000} 
                iterationCount='infinite' 
            />
            <Animatable.View 
                style={style.unoSquare} 
                animation="rotate"
                easing="linear"
                duration={7000} 
                direction="reverse"
                iterationCount='infinite'>
                <View style={style.circleDim}/>
            </Animatable.View>
            {props.processing?
                <Text style={style.textVolume}>*Thinks*</Text> 
            : null}
        </View>
    );
};

// Start Export Component //
export const WaveformActive = (props) => {

    return (
        <View style={style.animatedWaveformContainer}>
            <Animatable.View 
                style={style.animatedCircle} 
                animation={sonar} 
                easing={growEasing}
                duration={growDuration} 
                delay={delay1}
                iterationCount='infinite' 
            />
            <Animatable.View 
                style={style.animatedCircle} 
                animation={sonar} 
                easing={growEasing}
                duration={growDuration} 
                delay={delay2}
                iterationCount='infinite' 
            />
            <Animatable.View 
                style={style.animatedCircle} 
                animation={sonar}
                easing={growEasing}
                duration={growDuration}  
                delay={delay3}
                iterationCount='infinite'
            />
            <View style={style.unoCircle}>
                <View style={style.circleDim}/> 
                <Text style={style.textStatus}>{props.textStatus}</Text>
            </View>
        </View>
    );
};
