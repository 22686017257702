import React from 'react';
import {View} from 'react-native';
import {style} from '../../Styles/Style.js';
import {WaveformRest, WaveformProcess, WaveformActive} from '../Single/Waveform.js';

// Start Component //
const UnoWaveform = (props) => {
   
    return (
        <View style={style.mainSection}>
            {props.playing && !props.processing? 
                <WaveformActive 
                    textStatus={props.textStatus}/> 
                    : null} 
            {!props.playing && !props.processing? 
                <WaveformRest 
                    textStatus={props.textStatus} 
                    welcomeSubtitle={props.welcomeSubtitle} 
                    welcome={props.welcome}
                    isUser={props.isUser}
                    confirm={props.confirm}/> 
                    : null}
            {props.processing?
                <WaveformProcess
                    processing={props.processing} 
                    textStatus={props.textStatus}/> 
                    : null}
        </View>
    );
};

export default UnoWaveform;
