import React, {useState} from 'react';
import {Pressable, View, Text, Image, TextInput} from 'react-native';
import {style} from '../../Styles/Style.js';
import {xanoSendMessage} from '../../Utilities/Apis.js';

// Accept and Send Buttons Icons //
const sendIcon = require('../../assets/Images/UnoSend.png');
const successIcon = require('../../assets/Images/UnoAccept.png')

// Start Component //
const MessageInput = () => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputText, setInputText] = useState("")
    const [messageSent, setMessageSent] = useState(false)

    // Handle Sending Message //
    const handleSend = async () => {
        if (!inputText) {
            return;
        };
        const xanoMessage = await xanoSendMessage(inputText);
        setInputText("");
        if (xanoMessage.responseStatus === 202) {
            setMessageSent(true);
            setTimeout(() => {
                setMessageSent(false);
            }, 3000)
        }
    };

    return (
        <View style={style.inputContainer}>
            {!isFocused && !inputText? 
                <View style={style.inputPlaceholder}>
                    {messageSent?
                        <View style={style.sentMessage}>
                            <Image 
                                style={style.icon16}
                                source={successIcon}/>
                            <Text style={style.sentMessageText}>Message received, thank you.</Text>
                        </View> :
                        <Text style={style.inputPlaceholderText}>Need help? Have some feedback or ideas about new features for Uno? Leave it all here and I'll reply back.</Text>
                    }
                </View> : null}
            <TextInput
                style={style.textInput}
                multiline={true}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChangeText={setInputText}
                value={inputText}>
            </TextInput>
            <Pressable style={style.inputSendButton}
                onPress={handleSend}>
                <Image
                    style={style.icon16}
                    source={sendIcon}/>
            </Pressable>
        </View>
    );
};

export default MessageInput;
