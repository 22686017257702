import React, {useState, useEffect} from 'react';
import {Pressable, View, Text, Image} from 'react-native';
import {color, style} from '../../Styles/Style.js';
import {xanoEditFeature} from '../../Utilities/Apis.js';

// Thumb Icons //
const thumbupOn = require('../../assets/Images/UnoThumbupOn.png');
const thumbupOff = require('../../assets/Images/UnoThumbupOff.png');

// Start Component //
const FeatureItem = (props) => {
    const [thumbup, setThumbup] = useState(false)

    // Set Thumb on if checked on backend //
    useEffect(() => {
        if (props.checked) {
            setThumbup(true)
        }
    }, []);

    // Toggle Thumb On/Off //
    const handleThumbUp = () => {
        if (!thumbup) {
            setThumbup(true);
            xanoEditFeature(true, props.id)
        } else {
            setThumbup(false)
            xanoEditFeature(false, props.id)
        }
    };

    return (
        <View style={style.featureContainer}>
            <View>
                <Text style={style.featureText}>{props.title}</Text>
            </View>
            <Pressable 
                style={style.touchFeatureNeed}
                onPress={handleThumbUp}>
                <Text style={[style.featureThumbText, thumbup && {color: color.success}]}>{thumbup? "I need this" : "I don't need this"}</Text>
                <Image 
                    style={style.thumbMedium} 
                    source={thumbup? thumbupOn : thumbupOff}/>
            </Pressable>
        </View>
    );
};

export default FeatureItem;
