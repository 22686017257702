import React, {useState, useRef} from 'react';
import {View, Text} from 'react-native';
import {style} from '../../Styles/Style';
import MenuTitle from './MenuTitle';
import SubMenuItem from './SubMenuItem';
import SubMenuItemEdit from './SubMenuItemEdit';
import Spacer from './Spacer';
import {xanoEditUser} from '../../Utilities/Apis';
import secureLocalStorage from 'react-secure-storage';

// Start Export Component //
export const SubMenuAccount = (props) => {
    const [inputText, setInputText] = useState("");
    const [editName, setEditName] = useState(false);
    const nameValue = useRef("");
    const emailValue = useRef("");

     // Set Name & Email //
    nameValue.current = secureLocalStorage.getItem("userName");
    emailValue.current = secureLocalStorage.getItem("userEmail");

    // Submit & Cancel buttons //
    const handlePressNameOk = async () => {
        const userName = await xanoEditUser(inputText);
        secureLocalStorage.setItem("userName", userName);
        nameValue.current = secureLocalStorage.getItem("userName");
        props.setStatus("Ask me anything, " + nameValue.current);
        setEditName(false);
        setInputText("")
    };
    const handlePressCancel = () => {
        setInputText("");
        setEditName(false);
    };

    // Blur handling //
    const handleBlur = () => {
        if (!inputText) {
            setEditName(false);
        }
    };

    // Edit fields //
    const handleEditName = () => {
        setEditName(true);
    };

    return (
        <View style={style.menuSubItems}>
            <MenuTitle 
                title="Account"
                onPress={props.onPressTitle}/>
            <Spacer height={14}/>
            {!editName? 
                <SubMenuItem 
                    itemText={nameValue.current}
                    onPress={handleEditName}
                /> : null}
            {editName? 
                <SubMenuItemEdit
                    name="editName"
                    placeholder="Enter a new name"
                    focusFalse={handleBlur}
                    onChangeText={setInputText}
                    value={inputText}
                    handlePressOk={handlePressNameOk}
                    handlePressCancel={handlePressCancel}
                /> : null}
            <Text style={style.menuSubItemText}>{emailValue.current}</Text>
        </View>
    );
};
