import React, {useState, useEffect} from 'react';
import {View, ScrollView, Text, FlatList} from 'react-native';
import {color, style} from '../../Styles/Style';
import Spacer from './Spacer';
import Line from './Line';
import MessageInput from './MessageInput';
import FeatureItem from './FeatureItem';
import {xanoGetFeatures} from '../../Utilities/Apis';

// Start Component //
const Feedback = () => {
    const [featureList, setFeatureList] = useState();

    useEffect(() => {
        getFeatures()
    }, []);

    // Get Features List from Backend //
    const getFeatures = async () => {
        const features = await xanoGetFeatures();
        setFeatureList(features);
    };

    // Render Item //
    const renderItem = ({item}) => (
        <>
            <FeatureItem 
                id={item.id}
                title={"• " + item.title}
                checked={item.checked? true : null}/>
            <Line 
                width={'100%'} 
                height={0.5} 
                color={color.darkBlue80} 
                spaceTop={8}
                spaceBelow={12}/>
        </>
    );

    return (
        <ScrollView 
            style={style.feedbackScroll}
            showsVerticalScrollIndicator={false}>
            <Text style={style.feedbackItem}>Help & Feedback</Text>
            <MessageInput/>
            <Spacer height={48}/>
            <Text style={style.feedbackItem}>Upcoming features</Text>
            <FlatList
                data={featureList}
                keyExtractor={item => item.id}
                renderItem={renderItem}
            />
        </ScrollView>
    );
};

export default Feedback;
