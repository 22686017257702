import React from 'react';
import {BlurView} from 'expo-blur';
import {View} from 'react-native';
import {style} from '../../Styles/Style.js';

// Start Component //
const BlurPanel = (props) => {

    return (
        <View style={style.panelContainer}>
            <BlurView
                intensity={24}
                style={style.blurView}>
                    <View style={style.blurViewTint}/>
                    {props.children}
            </BlurView>
        </View>
    );
};

export default BlurPanel;
