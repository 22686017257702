import React, {useState} from 'react';
import BlurPanel from '../Single/BlurPanel';
import Menu from '../Single/Menu';
import {SubMenuAccount} from '../Single/SubMenu';
import secureLocalStorage from 'react-secure-storage';

// Start Component //
const MenuPanel = (props) => {
    const [showMenu, setShowMenu] = useState(true);
    const [showAccount, setShowAccount] = useState(false);

    // Account Item //
    const handlePressAccount = () => {
        setShowMenu(false);
        setShowAccount(true);
    };
    const accountGoBack = () => {
        setShowAccount(false);
        setShowMenu(true);
    };

    // Logout Item //
    const handlePressLogout = () => {
        secureLocalStorage.removeItem("authToken");
        secureLocalStorage.removeItem("userName");
        secureLocalStorage.removeItem("userEnabled");
        secureLocalStorage.removeItem("isUser");
        secureLocalStorage.removeItem("convHistory");
        navigation.navigate("Welcome");
    }

    return (
        <BlurPanel>
            {showMenu? 
                <Menu
                    onPressAccount={handlePressAccount}
                    onPressLogout={handlePressLogout}
                /> : null}
            {showAccount?
                <SubMenuAccount
                    onPressTitle={accountGoBack}
                    setStatus={props.setStatus}
                /> : null}
        </BlurPanel>
    );
};

export default MenuPanel;
