import secureLocalStorage from 'react-secure-storage';
import {global} from './Global'

// Xano API //
const xanoUrl = "https://x8ki-letl-twmt.n7.xano.io/api:aMyxWMb4";

export const xanoRequestAccess = async (email) => {
    const endpoint = "/auth/magic-link";
    const parameter = "?email=" + email;
    try {
        const requestUrl = xanoUrl + endpoint + parameter;
        const response = await fetch(requestUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        });
        const jsonResponse = await response.json();
        return jsonResponse.isUser;
    } catch (error) {
        console.log("There's been an error", error)
    }
};

export const xanoVerifyToken = async (magicToken) => {
    const endpoint = "/auth/magic-login";
    try {
        const requestUrl = xanoUrl + endpoint;
        const data = {
            "magic_token": magicToken
        };
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        console.log("There's been an error", error)
    }
};

export const xanoEditUser = async (name) => {
    const endpoint = "/user/edit";
    try {
        const requestUrl = xanoUrl + endpoint;
        const data = {
            "name": name
        }
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${secureLocalStorage.getItem("authToken")}`
            },
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        return jsonResponse.userName;
    } catch (error) {
        console.log("There's been an error", error)
    }
};

export const xanoSendMessage = async (message) => {
    const endpoint = "/message/send";
    const requestUrl = xanoUrl + endpoint;
    const data = {
        "message": message
    };
    try {
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${secureLocalStorage.getItem("authToken")}`
            },
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        console.log("There's been an error", error)
    }
};

export const xanoGetFeatures = async () => {
    const endpoint = "/feature/list";
    const requestUrl = xanoUrl + endpoint;
    try {
        const response = await fetch(requestUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${secureLocalStorage.getItem("authToken")}`
            }
        });
        const jsonResponse = await response.json();
        return jsonResponse.features;
    } catch (error) {
        console.log("There's been an error", error)
    }
};

export const xanoEditFeature = async (need, featureId) => {
    const endpoint = "/feature/edit";
    const requestUrl = xanoUrl + endpoint;
    const data = {
        "need": need,
        "feature_id": featureId
    };
    try {
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${secureLocalStorage.getItem("authToken")}`
            },
            body: JSON.stringify(data)
        })
    } catch (error) {
        console.log("There's been an error", error)
    }
};

export const xanoReduceCalls = async () => {
    const endpoint = "/user/calls/reduce";
    const requestUrl = xanoUrl + endpoint;
    try {
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${secureLocalStorage.getItem("authToken")}`
            }
        })
        const jsonResponse = await response.json()
        return jsonResponse;
    } catch (error) {
        console.log("There's been an error", error)
    }
};

// Convert Audio Data into a Web Link //
export const cloudinaryCall = async (audioData) => {
    const cloudinaryUrl = "https://api.cloudinary.com/v1_1/dlnhocjzb/video/upload";
    try {
        const data = {
            "upload_preset": "unoAudio",
            "file": audioData,
        };
        const response = await fetch(cloudinaryUrl, {
            method: "POST",
            headers: {
                "Accept": "video/webm",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        return jsonResponse.secure_url;
    } catch (error) {
        console.log("There's been an error:", error)
    };
};

// Convert Audio from Web Link into Text //
export const deepgramCall = async (url) => {
    const deepgramUrl = "https://api.deepgram.com/v1/listen?detect_language=true&punctuate=true";
    try {
        const data = {
            url
        };
        const response = await fetch(deepgramUrl, {
            method : "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Token ${secureLocalStorage.getItem("deepToken")}`
            },
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        return jsonResponse.results.channels[0].alternatives[0].transcript;
    } catch (error) {
        console.log("There's been an error", error)
    };
};

// Prompt request to Open Ai //
export const openaiCall = async (prompt, getName) => {
    const openaiUrl = "https://api.openai.com/v1/chat/completions";
        try {
            const instructions = global.instructions;
            const history = secureLocalStorage.getItem("convHistory");
            const data = {
                "model": "gpt-3.5-turbo",
                "messages": [
                    {"role": "system", "content": instructions + history},
                    {"role": "user", "content": prompt}],
                "stream": true
            }
            const response = await fetch(openaiUrl, {
                method : "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${secureLocalStorage.getItem("openToken")}`
                },
                body: JSON.stringify(data)
            });
            const stream = response.body;
            if (getName) {
                const instruction = "Please extract the name provided by the user in a JSON object format with the property 'userName' and the name of the user as value";
                const data = {
                    "model": "gpt-3.5-turbo",
                    "messages": [
                        {"role": "system", "content": instruction},
                        {"role": "user", "content": prompt}],
                }
                const response = await fetch(openaiUrl, {
                    method : "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${secureLocalStorage.getItem("openToken")}`
                    },
                    body: JSON.stringify(data)
                });
                const jsonResponse = await response.json();
                const parseName = JSON.parse(jsonResponse.choices[0].message.content).userName;
                xanoEditUser(parseName);
                secureLocalStorage.setItem("userName", parseName);
            }
            return stream;
        } catch (error) {
            console.log("There's been an error in openai call: ", error)
        };
};


export const msSpeech = async (text) => {
    const endpoint = "https://eastus.tts.speech.microsoft.com/cognitiveservices/v1";
    const token = secureLocalStorage.getItem("msToken");
    const maleVoice = `xml:gender='Male' name="en-US-BrianNeural"`;
    const femaleVoice = `xml:gender='female' name="en-US-SaraNeural"`;
    const ssml = `<speak version='1.0' xml:lang='en-US'>
    <voice xml:lang='en-US' ${maleVoice}>
        <prosody pitch="-7.00%">
            ${text}
        </prosody>
    </voice>
    </speak>`;
    try {
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/ssml+xml",
                "X-Microsoft-OutputFormat": "audio-48khz-96kbitrate-mono-mp3",
                "Ocp-Apim-Subscription-Key": token,
            },
            body: ssml
        })
        return response;
    } catch (error) {
        console.log("There's been an error", error)
    }
};
