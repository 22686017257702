import React from 'react';
import {Pressable, View, Text, Image} from 'react-native';
import {style} from '../../Styles/Style.js';

// Dark Arrow Left Icon //
const backArrow = require('../../assets/Images/UnoLeftDark.png');

// Start Component //
const Confirmation = (props) => {

    return (
        <View style={style.instructContainer}>
            <View style={style.instructTextView}>
                <Text style={style.instructTitle}>Confirmation email sent to</Text>
                <Text style={style.instructTitle}>{props.emailSent}</Text>
                <Text style={style.textSubConfirm}>Please check it and click "Confirm email"</Text>
            </View>
            <Pressable 
                style={style.buttonUnoContainer}
                onPress={props.onPress}>
                <Image 
                    style={style.arrowMedium} 
                    source={backArrow}/>
                <Text style={style.buttonConfirmGoBackText}>Not you? Go back</Text>
            </Pressable>
        </View>
    );
};

export default Confirmation;
