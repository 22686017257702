import {Audio} from 'expo-av';
import {cloudinaryCall, deepgramCall, openaiCall, msSpeech, xanoReduceCalls} from './Apis';
import {toBase64, toBlob, timeElapsed} from './Helpers';
import secureLocalStorage from 'react-secure-storage';

// Local scope variables //
let startTime, locSetIsPlaying, locAudioSound, locSetIsProcessing, locSetQuestion, 
locGetName, exchangeHistory, availableCalls;
let sentenceQueue = [], audioQueue = [], isProcessing = false, isPlaying = false;

// Aborting //
let abort = false;
export const aborting = () => {
    abort = true;
    stopAudio(locAudioSound);
    if (isPlaying) {
        locSetQuestion("");
    };
};

// Start Recording //
export const startRecording = async (setIsRecording) => {
    try {
        await Audio.requestPermissionsAsync();
        await Audio.setAudioModeAsync({
            allowsRecordingIOS: true,
            playsInSilentModeIOS: true,
        });
        const {recording} = await Audio.Recording.createAsync(Audio.RecordingOptionsPresets.HIGH_QUALITY);
        setIsRecording(recording);
        abort = false;
    } catch (err) {
        console.error('Failed to start recording', err);
    }
};

// Stop Recording //
export const stopRecording = async (isRecording, setIsRecording, audioSound, setIsPlaying, 
    setIsProcessing, setQuestion, getName) => {
    if (abort || availableCalls < 1) {
        return;
    };
    // startTime = new Date().getTime();
    setIsRecording(undefined);
    await isRecording.stopAndUnloadAsync();
    await Audio.setAudioModeAsync({allowsRecordingIOS: false});
    const uri = isRecording.getURI();
    unoTalk(uri);
    locSetIsPlaying = setIsPlaying;
    locSetIsProcessing = setIsProcessing;
    locAudioSound = audioSound;
    locSetQuestion = setQuestion;
    locGetName = getName;
};

    // Chain for audio-to-text, text-to-response, response-to-audio processing //
    const unoTalk = async (uri) => {
        if (abort || availableCalls < 1) {
            return;
        }
        const audioData = await toBase64(uri);
        const cloudinaryResult = await cloudinaryCall(audioData);
        const deepgramResult = await deepgramCall(cloudinaryResult);
        locSetQuestion(`"${deepgramResult}"`);
        locSetIsProcessing(true);
        if (deepgramResult !== "") {
            exchangeHistory = ` User question: ${deepgramResult}`;
            const openaiResult = await openaiCall(deepgramResult, locGetName);
            decodeStream(openaiResult);
        }
    };

    // Decode stream response into sentences for Text to Speech //
    const decodeStream = async (data) => {
        availableCalls = await xanoReduceCalls();
        if (abort || availableCalls.calls < 1) {
            return;
        };
        if (!availableCalls.id) {
            console.log("no user: " + availableCalls.id)
            secureLocalStorage.removeItem("authToken")
            navigation.navigate("Welcome");
        };
        const reader = data.getReader();
        const decoder = new TextDecoder;
        let streamWords = "";
        let sentence = "";
        let fullResponse = "";
        while (true) {
            const {done, value} = await reader.read();
            if (done) {
                break;
            };
            const chunk = decoder.decode(value);
            const lines = chunk.split('data: ');
            const filteredLines = lines.filter((line) => line.trim() !== "" && line.trim() !== "[DONE]");
            const parsedLines = filteredLines.map((entry) => JSON.parse(entry));
            for (let i = 0; i < parsedLines.length; i++ ) {
                if (parsedLines[i].choices[0].delta.content !== undefined && parsedLines[i].choices[0].delta.content !== "") {
                    streamWords += parsedLines[i].choices[0].delta.content;
                    if (streamWords.endsWith('.') || streamWords.endsWith('?')) {
                        sentence = streamWords;
                        sentenceQueue.push(sentence);
                        textToSpeech();
                        fullResponse += (" " + sentence);
                        streamWords = "";
                    };
                };
            }
        };
        exchangeHistory += ` System response:${fullResponse}`;
        let conversation = secureLocalStorage.getItem("convHistory");
        conversation += exchangeHistory;
        secureLocalStorage.setItem("convHistory", conversation);
    };

    // Text to Speech & Play //
    const textToSpeech = async () => {
        if (abort) {
            return;
        };        
        if (!isProcessing) {
            isProcessing = true;
            const item = sentenceQueue.shift();
            const response = await msSpeech(item);
            const url = await toBlob(response);
            audioQueue.push(url);
            isProcessing = false;
            processAudio();
        } else {
            setTimeout(() => {
                textToSpeech()
            }, 500);
        };
    };
    const processAudio = () => {
        if (!isPlaying) {
            isPlaying = true;
            const audioItem = audioQueue.shift();
            if (audioItem) {
                playAudio(audioItem, locAudioSound, locSetIsPlaying);
                // console.log("Playing sound: " + timeElapsed(startTime)); // Test time to response //
            };
        } else {
            return;
        };
    };

// Play and Stop Audio //
export const playAudio = async (audio, audioSound, setIsPlaying) => {
    locSetIsPlaying = setIsPlaying;
    locAudioSound = audioSound;
    try {
        await locAudioSound.loadAsync(audio);
        await locAudioSound.playAsync();
        if (locSetIsProcessing) {
            locSetIsProcessing(false);
        };
        locSetIsPlaying(true);
        locAudioSound.setOnPlaybackStatusUpdate(status => {
            if (status.didJustFinish) {
                stopAudio(locAudioSound);
            }
        }); 
    } catch (error) {
        console.log("There's been an error", error)
    };
};

export const stopAudio = (audio) => {
    if (audio && audioQueue.length === 0) {
        audio.unloadAsync();
        locSetIsPlaying(false);
        if (locSetQuestion) {
            locSetQuestion("");
        };
        isPlaying = false;
    } else if (audio && audioQueue.length > 0) {
        audio.unloadAsync();
        isPlaying = false;
        processAudio();
    };
};
