import React from 'react';
import {Pressable, Text} from 'react-native';
import {style} from '../../Styles/Style.js';

// Start Component //
const MenuItem = (props) => {

    return (
        <Pressable
            style={style.touchRow}
            onPress={props.onPress}>
            <Text style={props.style}>{props.title}</Text>
        </Pressable>
    );
};

export default MenuItem;
