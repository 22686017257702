import React from 'react';
import {View} from 'react-native';

// Start Component //
const Spacer = (props) => {

    return (
        <View style={{
            width: props.width, 
            height: props.height
        }}/>
    );
};

export default Spacer;
