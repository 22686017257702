// ---------- Views Sizes ---------- //

export const width = {

    // Width Percent //
    w10: {
        width: '100%'
    },
    w9: {
        width: '90%'
    },
    w8: {
        width: '80%'
    },
    w7: {
        width: '70%'
    },
    w6: {
        width: '60%'
    },
    w5: {
        width: '50%'
    },
    w4: {
        width: '40%'
    },
    w3: {
        width: '30%'
    },
    w2: {
        width: '20%'
    },
    w1: {
        width: '10%'
    }

};
export const height = {

    // Height Percent //
    h10: {
        height: '100%'
    },
    h9: {
        height: '90%'
    },
    h8: {
        height: '80%'
    },
    h7: {
        height: '70%'
    },
    h6: {
        height: '60%'
    },
    h5: {
        height: '50%'
    },
    h4: {
        height: '40%'
    },
    h3: {
        height: '30%'
    },
    h2: {
        height: '20%'
    },
    h1: {
        height: '10%'
    },

};

export const maxWidth = {

    mxW1: {
        maxWidth: 270,
    },
    mxW3 : {
        maxWidth: 550
    },
    
};

export const textSize = {

    ts1: {
        fontSize: 18
    },
    ts2: {
        fontSize: 16
    },
    ts3: {
        fontSize: 14
    },
    ts4: {
        fontSize: 12
    },
    ts5: {
        fontSize: 10
    },

};

export const textHeight = {

    th1: {
        lineHeight: 24
    },
    th2: {
        lineHeight: 22
    },
    th3: {
        lineHeight: 20
    },
    th4: {
        lineHeight: 18
    },
    th5: {
        lineHeight: 16
    },
    th6: {
        lineHeight: 14
    },
    th7: {
        lineHeight: 12
    },
    th8: {
        lineHeight: 10
    }

};

export const textSpace = {

    tsp1: {
        letterSpacing: 0
    },
    tsp2: {
        letterSpacing: 0.1
    },
    tsp3: {
        letterSpacing: 0.2
    }

};
