import React, {useState, useEffect, useRef} from 'react';
import {View, Image} from 'react-native';
import {color, style} from '../Styles/Style';
import {LinearGradient} from 'expo-linear-gradient';
import {Audio} from 'expo-av';
import Question from '../Components/Single/Question';
import UnoWaveform from '../Components/Block/UnoWaveform'
import Controls from '../Components/Block/Controls';
import MenuPanel from '../Components/Block/MenuPanel';
import FeedbackPanel from '../Components/Block/FeedbackPanel';
import secureLocalStorage from 'react-secure-storage';
import {assets} from '../Utilities/Assets';
import {startRecording, stopRecording, aborting, playAudio} from '../Utilities/Core';

// Background image //
const backgroundImg = require('../assets/Images/UnoBg.jpg')

// Set Audio object //
const audioSound = new Audio.Sound();

// Declare variables //
let i = 0;

// Start Component //
const Uno = () => {
    const [longPress, setLongPress] = useState(false);
    const [isRecording, setIsRecording] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false)
    const [question, setQuestion] = useState("");
    const [status, setStatus] = useState("");
    const [menuPanel, setMenuPanel] = useState(false);
    const [feedbackPanel, setFeedbackPanel] = useState(false);
    const [justVerified, setJustVerified] = useState(false);
    const [getName, setGetName] = useState(false);
    const nameValue = useRef("");

    // Set device variables //
    const userName = secureLocalStorage.getItem("userName");
    const userEnabled = secureLocalStorage.getItem("userEnabled");

    // Set user's name //
    if (userName === null) {
        nameValue.current = "";
    } else {
        nameValue.current = `, ${userName}`;
    };    

    // User Enabled, Verification Status and Playing Hello Audio //
    useEffect(() => {
        if (!userName && userEnabled) {
            setJustVerified(true);
            setGetName(true);
            setStatus(`Hi. What's your name? (Adjust volume)`);
            setTimeout(() => {
                setJustVerified(false);
            }, 3000)
        } else if (!userName && !userEnabled) {
            setJustVerified(true);
            setStatus("You'll be able to access very soon.");
            setTimeout(() => {
                setJustVerified(false);
            }, 3000)
        } else if (userName && userEnabled) {
            setStatus(`Ask me anything${nameValue.current}`);
        } else if (userName && !userEnabled) {
            setStatus("You'll be able to access very soon.");
        };
        return () => {
            audioSound.unloadAsync();
        }
    }, []);

    // Recording Functions //
    const handlePressIn = () => {
        if (userEnabled) {
            setLongPress(true);
            startRecording(setIsRecording)
        } else {
            setLongPress(true);
        };
    };
    const handlePressOut = () => {
        if (userEnabled && !isPlaying) {
            setLongPress(false);
            stopRecording(isRecording, setIsRecording, audioSound, setIsPlaying,
            setIsProcessing, setQuestion, getName, setGetName)
        } else if (!userEnabled && isPlaying) {
            aborting();
            setLongPress(false);
        } else if (!userEnabled && !isPlaying) {
            setLongPress(false);
            if (i >= 7) {i = 0};
            setTimeout(() => {
                playAudio(assets.waitingAudio[i], audioSound, setIsPlaying)
                setIsPlaying(true);
                i++;
            }, 1000);
        }
    };

    // Menu Button //
    const handleMenuPanel = () => {
        if (menuPanel) {
            setMenuPanel(false);
        } else {
            setMenuPanel(true);
            setFeedbackPanel(false);
        }
    };

    // Feedback Button //
    const handleFeedbackPanel = () => {
        if (feedbackPanel) {
            setFeedbackPanel(false);
        } else {
            setFeedbackPanel(true);
            setMenuPanel(false);
        }
    };

    return (
        <View style={style.mainContainer}>
            <Image 
                style={style.fsBackground}
                source={backgroundImg}/>
            <LinearGradient 
                style={style.topShadow}
                colors={[color.black, 0]} 
                start={{x: 0, y: 0}} 
                end={{x: 0, y: 1}}/>
            <LinearGradient 
                style={style.bottomShadow}
                colors={[0, color.black]} 
                start={{x: 0, y: 0}} 
                end={{x: 0, y: 1}}/>
            <Question
                question={question}/>
            <UnoWaveform 
                playing={isPlaying}
                processing={isProcessing}
                welcome={false}
                textStatus={isPlaying? "Answering..." : status}/>
            <Controls 
                isPlaying={isPlaying}
                aborting={aborting}
                press={longPress}
                pressIn={handlePressIn}
                pressOut={handlePressOut}
                menuOpen={menuPanel}
                toggleMenuPanel={handleMenuPanel}
                feedbackOpen={feedbackPanel}
                toggleFeedbackPanel={handleFeedbackPanel}
                verification={justVerified? true : false}/>
            {menuPanel? <MenuPanel setStatus={setStatus}/> : null}
            {feedbackPanel? <FeedbackPanel/> : null}
        </View>
    );
};

export default Uno;
