import React, {useState, useEffect} from 'react';
import {View, Image} from 'react-native';
import {color, style} from '../Styles/Style';
import {LinearGradient} from 'expo-linear-gradient';
import {Audio} from 'expo-av';
import UnoWaveform from '../Components/Block/UnoWaveform'
import EmailInput from '../Components/Single/EmailInput';
import Confirmation from '../Components/Single/Confirmation';
import secureLocalStorage from 'react-secure-storage';
import {getParameter, getToken} from '../Utilities/Helpers';

// Background image //
const backgroundImg = require('../assets/Images/UnoBg.jpg');

// Set Audio object //
const audioSound = new Audio.Sound();

// Start Component //
const Welcome = ({navigation}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [onboard, setOnboard] = useState("welcome");
    const [email, setEmail] = useState("");

    // Get Magic Token //
    const magicToken = getParameter("token");

    // Set device variables //
    const tokenCheck = secureLocalStorage.getItem("authToken");
    const userCheck = secureLocalStorage.getItem("isUser");

    // Handling initial login status //
    useEffect(() => {
        if (tokenCheck) {
            setIsPlaying(false)
            audioSound.unloadAsync();
            navigation.navigate("Uno");
            setOnboard("welcome");
        } else if (magicToken) {
            getToken(navigation, magicToken)
        } else {
            return;
        }
    }, []);

    // Set Onboarding status //
    const setOnboarding = (inputText) => {
        setOnboard("confirm");
        setEmail(inputText);
    };

    // Go back to Login //
    const confirmBack = () => {
        setOnboard("welcome");
        setIsPlaying(false);
        secureLocalStorage.removeItem("userName");
        secureLocalStorage.removeItem("isUser");
    };

    return (
        <View style={style.mainContainer}>
            <Image 
                style={style.fsBackground}
                source={backgroundImg}/>
            <LinearGradient 
                style={style.bottomShadow}
                colors={[0, color.black]} 
                start={{x: 0, y: 0}} 
                end={{x: 0, y: 1}}/>
            <UnoWaveform 
                welcome={onboard === "welcome"? true : false}
                confirm={onboard === "confirm"? true : false}
                isUser={userCheck}
                playing={isPlaying}
                welcomeSubtitle={"Use Natural Oratory"}/>
            {onboard === "welcome"?
                <EmailInput
                    setOnboarding={setOnboarding}/> : null}
            {onboard === "confirm"?
                <Confirmation 
                    emailSent={email}
                    onPress={confirmBack}/> : null}
        </View>
    );
};

export default Welcome;
