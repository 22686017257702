import React from 'react';
import {Image, Pressable} from 'react-native';
import {style} from '../../Styles/Style.js';

// Feedback Toggle Button Icons //
const questionIcon = require('../../assets/Images/UnoQuestion.png');
const questionClose = require('../../assets/Images/UnoClose.png');

// Start Component //
const QuestionButton = (props) => {

    return (
        <Pressable
            style={style.touchMedium}
            onPress={props.toggleFeedbackPanel}>
            <Image
                style={style.icon20x20}
                source={props.feedbackOpen? questionClose : questionIcon} 
            />
        </Pressable>
    );
};

export default QuestionButton;
