import {StyleSheet} from 'react-native';
import {fonts} from './Fonts.js';
import {colors} from './Colors.js';
import {textStyle} from './Texts.js'
import {margin, padding} from './Spacing.js';
import {width, height, textSize, textHeight, textSpace, maxWidth} from './Sizing.js';

export const font = {...fonts};
export const color = {...colors};
export const text = {...textStyle}
export const style = StyleSheet.create ({

    /* 
        • Font sizes are in multiples of 2, from 10 (min size) to 18.
        • Border radius and Assets sizes are in multiples of 2.
        • Margins and padding sizes are in multiples of 4.
        • Views sizes are in multiples of 10.
    */

    ...margin,
    ...padding,
    ...width,
    ...height,
    ...maxWidth,

// ---------- Screens ---------- //

    mainContainer: {
        flex: 1,
        ...width.w10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.darkerGrey
    },

// ---------- Sections ---------- //

    questionSection: {
        flex: 1,
        position: 'absolute',
        top: 100,
        ...maxWidth.mxW1,
        textAlign: 'center',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

    mainSection: {
        flex: 4,
        ...width.w10,
        paddingTop: 100,
        justifyContent: 'center',
        alignItems: 'center'
    },

    controlSection: {
        zIndex: 1,
        ...width.w10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

// ---------- Touchables ---------- //

    touchMedium: {
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center'
    },
    touchLarge: {
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    touchRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 35
    },
    touchFeatureNeed: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },

// ---------- Containers ---------- //

    // Button Welcome //
    buttonWelcomeContainer: {
        flexDirection: 'row',
        width: 230,
        height: 60,
        backgroundColor: colors.barium,
        borderRadius: 30,
        justifyContent: 'center',
        alignItems: 'center'
    },

    // Button Uno //
    buttonUnoContainer: {
        flexDirection: 'row',
        width: 180,
        height: 50,
        backgroundColor: colors.barium,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center'
    },

    // Input Containers //
    inputContainer: {
        ...width.w10, 
        height: 215, 
        borderWidth: 2, 
        borderRadius: 24, 
        borderColor: colors.barium, 
        justifyContent: 'center'
    },
    inputPlaceholder: {
        zIndex: 0, 
        ...width.w10,
        ...padding.p9, 
        position: 'absolute', 
        justifyContent: 'center',
    },

    sentMessage: {
        width: '100%', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
        
    emailInputContainer: {
        flex: 1.5,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'blue'
    },
    emailInputView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 250,
        height: 50,
        borderWidth: 2, 
        borderRadius: 25, 
        borderColor: colors.darkBlue40, 
    },

    // Confirmation Container //
    instructContainer: {
        zIndex: 1,
        flex: 1.5, 
        ...width.w10,
        alignItems: 'center', 
        justifyContent: 'flex-start'
    },
        instructTextView: {
            ...width.w10,
            height: 110, 
            justifyContent: 'center',
            alignItems: 'center'
        },

    verifiedView: {
        ...width.w10,
        height: 110,
        flexDirection: 'row', 
        justifyContent: 'center',
        alignItems: 'center'
    },
    // Modal Panel //
    panelContainer: {
        flex: 1, 
        position: 'absolute',
        ...width.w10,
        ...height.h10,
        ...maxWidth.mxW3,
        justifyContent: 'flex-start'
    },
    menuItems: {
        ...width.w10,
        ...height.h10, 
        ...padding.p9, 
        ...padding.pt15, 
        justifyContent: 'space-between', 
        position: 'absolute',
    },
    menuSubItems: {
        ...width.w10,
        ...height.h10,
        ...padding.p6, 
        ...padding.pt15, 
        justifyContent: 'flex-start', 
        position: 'absolute'
    },

    // Feature Container //
    featureContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    // Input Container //
    talkContainer: {
        flexDirection: 'row',
        ...margin.mh7
    },

    // Animation Container //
    animatedWaveformContainer: {
        flex: 1, 
        justifyContent: 'center', 
        alignItems: 'center', 
    },

    // Edit Item //
    editItemContainer: {
        ...width.w10, 
        height: 35, 
        flexDirection: 'row', 
        backgroundColor: colors.darkGrey, 
        borderRadius: 8, 
        justifyContent: 'flex-end'
    },
        editTextInput: {
            flex: 1, 
            borderRadius: 8, 
            ...padding.ph3, 
            color: colors.barium, 
            ...textSpace.tsp2
        },
        inputButtonsContainer: {
            flexDirection: 'row', 
            ...padding.ph2
        },
            inputButton: {
                width: 35, 
                height: 35, 
                justifyContent: 'center', 
                alignItems: 'center'
            },

// ---------- Scrollers ---------- //

    feedbackScroll: {
        ...width.w10, 
        ...height.h10, 
        position: 'absolute', 
        ...padding.p9, 
        ...padding.pt17
    },

// ---------- Elements ---------- //

    unoCircle: {
        width: 260,
        height: 260,
        borderWidth: 6,
        borderRadius: 80,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.green,
        opacity: 1
    },
    unoSquare: {
        width: 250,
        height: 250,
        position: 'absolute',
        borderWidth: 6,
        borderRadius: 80,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.green,
        opacity: 1
    },

    blurView: {
        ...width.w10, 
        ...height.h8,
        borderBottomStartRadius: 24,
        borderBottomEndRadius: 24, 
        overflow: 'hidden',
    },

    blurViewTint: {
        ...width.w10,  
        ...height.h10, 
        backgroundColor: colors.darkBlue, 
        opacity: 0.85
    },

    restCircle: {
        position: 'absolute',
        ...width.w10,
        ...height.h10,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 6,
        borderRadius: 80,
        borderColor: colors.green,
    },

    animatedCircle: {
        position: 'absolute',
        ...width.w10,
        ...height.h10,
        borderWidth: 6,
        borderRadius: 80,
        borderColor: colors.green,
        opacity: 0.3,
    },

    circleDim: {
        position: 'absolute',
        ...width.w10,
        ...height.h10,
        borderRadius: 80,
        backgroundColor: colors.black,
        opacity: 0.1,
    },

    topShadow: {
        ...width.w10, 
        height: 200, 
        position: 'absolute', 
        top: 0, 
        opacity: 0.3
    },
    bottomShadow: {
        ...width.w10, 
        height: 200, 
        position: 'absolute', 
        bottom: 0, 
        opacity: 0.3
    },

    // Buttons //
    inputSendButton: {
        zIndex: 2, 
        width: 34, 
        height: 34,
        position: 'absolute',
        bottom: 8,
        right: 0,
        backgroundColor: colors.green, 
        borderRadius: 18,
        ...margin.mr2, 
        justifyContent: 'center', 
        alignItems: 'center'
    },

    loginSendButton: {
        zIndex: 2, 
        width: 34, 
        height: 34,
        backgroundColor: colors.green, 
        borderRadius: 18,
        ...margin.mr2, 
        justifyContent: 'center', 
        alignItems: 'center'
    },

// ---------- Images ---------- //

    fsBackground: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        resizeMode: 'cover',
        opacity: 0.4
    },

// ---------- Icons ---------- //

    // Uno Logo //
    unoLogo: {
        width: 138, 
        height: 38, 
        resizeMode: 'contain', 
        marginTop: 28,
        marginBottom: 12
    },

    // Talk Icon //
    talkMedium: {
        resizeMode: 'contain',
        width: 14,
        height: 20,
        ...margin.mr2
    },
    talkSmall: {
        resizeMode: 'contain',
        width: 14,
        height: 16,
        ...margin.mr2
    },

    // Arrows Icons //
    arrowSmall: {
        resizeMode: 'contain',
        width: 6,
        height: 10,
    },
    arrowMedium: {
        resizeMode: 'contain',
        width: 8,
        height: 12,
    },

    // Edit Icons //
    editSmall: {
        resizeMode: 'contain',
        width: 8,
        height: 10,
    },

    // Send Icon //
    icon20: {
        width: 20, 
        height: 20, 
        resizeMode: 'contain'
    },
    icon16: {
        width: 16, 
        height: 16, 
        resizeMode: 'contain'
    },

    // Menu Button //
    icon20x20: {
        resizeMode: 'contain',
        width: 20,
        height: 20
    },

    thumbSmall: {
        resizeMode: 'contain',
        width: 12,
        height: 14,
        ...margin.mr2
    },
    thumbMedium: {
        resizeMode: 'contain',
        width: 14,
        height: 18,
    },

    inputIcon: {
        width: 14, 
        height: 14, 
        resizeMode: 'contain'
    },

// ---------- Texts ---------- //

    // Talk Buttons //
    buttonUnoText: {
        ...textStyle.t3,
        color: colors.darkBlue,
    },
    buttonWelcomeText: {
        ...textStyle.t1,
        color: colors.darkBlue,
    },
    buttonConfirmGoBackText: {
        ...textStyle.t3,
        color: colors.darkBlue,
        ...margin.ml2
    },

    emailInputTitle: {
        zIndex: 3,
        ...textStyle.t2,
        width: 230,
        textAlign: 'center',
        color: colors.barium,
    },
    sentMessageText: {
        ...textStyle.t2,
        ...margin.ml2,
        color: colors.success
    },

    // Menu Panel //
    menuItem: {
        ...textStyle.t1,
        color: colors.barium,
        ...padding.pv3
    },
    menuTitle: {
        ...textStyle.t1,
        color: colors.barium,
        ...padding.pv3
    },
    menuSubItem: {
        ...textStyle.t4,
        ...padding.ph3,
        color: colors.barium        
    },
    menuSubItemText: {
        ...textStyle.t5,
        ...padding.ph3,
        color: colors.darkBlue60,
        position: 'absolute',
        bottom: 48
    },
    logoutItem: {
        ...textStyle.t1,
        color: colors.error,
        ...padding.pv3
    },

    // Feedback Panel //
    feedbackItem: {
        ...textStyle.t1,
        color: color.barium, 
        marginBottom: 18
    },

    // Uno Text Status //
    textStatus: {
        ...textStyle.t2,
        width: 235,
        textAlign: 'center',
        color: colors.lightGrey,
        ...margin.mt1,
        ...padding.p4
    },
    // Uno Text Welcome //
    textWelcome: {
        ...textStyle.t7,
        textAlign: 'center',
        color: colors.darkBlue40,
    },

    // Uno Confirmation //
    textVolume: {
        color: colors.barium, 
        ...margin.mt3,
        ...textStyle.t2,
        zIndex: 1
    },
    instructTitle: {
        color: colors.barium, 
        ...textStyle.t2,
        width: 250,
        textAlign: 'center'
    },

    verifiedTitle: {
        color: colors.success, 
        ...textStyle.t2,
        ...margin.ml2,
        textAlign: 'center'
    },
    textSubConfirm: {
        color: colors.barium,
        ...textStyle.t7,
        ...margin.mt3
    },

    // Feature Panel //
    featureText: {
        fontFamily: font.light,
        ...textSize.ts3,
        ...textHeight.th3,
        ...textSpace.tsp3,
        color: colors.barium,
        
    },
    featureThumbText: {
        ...textStyle.t8,
        color: colors.darkBlue80,
        ...margin.mr2
    },

    inputPlaceholderText: {
        ...textStyle.t4,
        color: colors.darkBlue60, 
        textAlign: 'center'
    },
    textInput: {
        zIndex: 1, 
        ...textStyle.t4,
        ...width.w10, 
        ...height.h10, 
        ...padding.ph4, 
        ...padding.pv8, 
        color: colors.barium, 
        textAlign: 'center', 
        borderRadius: 24
    },
    emailTextInput: {
        zIndex: 1,
        ...width.w10,
        ...height.h10,
        borderRadius: 25,
        ...textStyle.t4,
        ...padding.ph4,
        ...margin.mr2,
        color: colors.barium
    },

    // Question //
    questionText: {
        ...textStyle.t4,
        color: colors.barium
    },
});
