export const colors = {
    black: '#000000',
    darkerGrey: '#202020',
    darkGrey: '#3D3D3D',
    lightGrey: '#EDEDED',
    darkBlue: '#283C5A',
    darkBlue80: '#4C637F',
    darkBlue60: '#788A9E',
    darkBlue40: '#A5B2BF',
    darkBlue20: '#D4DADD',
    barium: '#CDE6E6',
    green: '#AAFABE',
    eggs: '#FFF6BC',
    purple: '#E6D7FF',
    success: '#61DCA7',
    error: '#FF5A2D'
};