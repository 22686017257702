import React from 'react';
import {Pressable, View, Text, Image} from 'react-native';
import {style} from '../../Styles/Style.js';

// Edit Button Icon //
const editIcon = require('../../assets/Images/UnoEdit.png');

// Start Component //
const SubMenuItem = (props) => {

    return (
        <Pressable 
            style={style.touchRow}
            onPress={props.onPress}>
            <Text style={style.menuSubItem}>{props.itemText}</Text>
            <View style={style.touchMedium}>
                <Image 
                    style={style.editSmall} 
                    source={editIcon}/>
            </View>
        </Pressable>
    );
};

export default SubMenuItem;
