import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import Uno from './Screens/Uno';
import Welcome from './Screens/Welcome';

const Stack = createNativeStackNavigator();
const App = () => {
    return (
        <NavigationContainer>
            <Stack.Navigator 
                initialRouteName="Welcome"
                screenOptions={{headerShown: false}}>
                    <Stack.Screen name="Welcome" component={Welcome}/>
                    <Stack.Screen name="Uno" component={Uno}/>
            </Stack.Navigator>
        </NavigationContainer>
    );
};

export default App;
