import React from 'react';
import {Image, Pressable} from 'react-native';
import {style} from '../../Styles/Style.js';

// Menu Toggle Button Icons //
const menuIcon = require('../../assets/Images/UnoMenu.png');
const menuIconClose = require('../../assets/Images/UnoClose.png')

// Start Component //
const MenuButton = (props) => {
    
    return (
        <Pressable
            style={style.touchMedium}
            onPress={props.toggleMenuPanel}>
            <Image
                style={style.icon20x20}
                source={props.menuOpen? menuIconClose : menuIcon} 
            />
        </Pressable>
    );
};

export default MenuButton;
