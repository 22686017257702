import React from 'react';
import {Pressable, View, Text, Image} from 'react-native';
import {style} from '../../Styles/Style.js';

// Button Left Icon //
const leftIcon = require('../../assets/Images/UnoLeft.png');

// Start Component //
const MenuTitle = (props) => {

    return (
        <Pressable 
            style={style.touchRow}
            onPress={props.onPress}>
            <View style={style.touchMedium}>
                <Image 
                    style={style.arrowSmall} 
                    source={leftIcon}/>
            </View>
            <Text style={style.menuTitle}>{props.title}</Text>
        </Pressable>
    );
};

export default MenuTitle;
