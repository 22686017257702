import React from 'react';
import {View} from 'react-native';
import {style} from '../../Styles/Style';
import MenuItem from './MenuItem';

// Start Component //
const Menu = (props) => {

    return (
        <View style={style.menuItems}>
            <View>
                <MenuItem 
                    style={style.menuItem}
                    title="Account"
                    onPress={props.onPressAccount}/>
            </View>
            <View>
                <MenuItem 
                    style={style.logoutItem}
                    title="Logout"
                    onPress={props.onPressLogout}/>
            </View>
        </View>
    );
};

export default Menu;
