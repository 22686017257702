import {fonts} from './Fonts.js';
import {textSize, textHeight, textSpace} from './Sizing.js';

const font = {...fonts};

export const textStyle = {
    t1: {
        fontFamily: font.extrabold,
        ...textSize.ts1,
        ...textHeight.th2,
        ...textSpace.tsp1,
    },
    t2: {
        fontFamily: font.semibold,
        ...textSize.ts2,
        ...textHeight.th1,
        ...textSpace.tsp1,
    },
    t3: {
        fontFamily: font.extrabold,
        ...textSize.ts3,
        ...textHeight.th4,
        ...textSpace.tsp1,
    },
    t4: {
        fontFamily: font.light,
        ...textSize.ts3,
        ...textHeight.th3,
        ...textSpace.tsp3
    },
    t5: {
        fontFamily: font.medium,
        ...textSize.ts4,
        ...textHeight.th4,
        ...textSpace.tsp1
    },
    t6: {
        fontFamily: font.semiboldItalic,
        ...textSize.ts4,
        ...textHeight.th4,
        ...textSpace.tsp1
    },
    t7: {
        fontFamily: font.light,
        ...textSize.ts4,
        ...textHeight.th5,
        ...textSpace.tsp3
    },
    t8: {
        fontFamily: font.medium,
        ...textSize.ts5,
        ...textHeight.th7,
        ...textSpace.tsp2
    }
}