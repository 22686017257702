export const margin = {

    // Margin Total //
    m1: {
        margin: 4
    },
    m2: {
        margin: 8
    },
    m3: {
        margin: 12
    },
    m4: {
        margin: 16
    },
    m8: {
        margin: 32
    },

    // Margin Horizontal//
    mh1: {
        marginHorizontal: 4
    },
    mh2: {
        marginHorizontal: 8
    },
    mh3: {
        marginHorizontal: 12
    },
    mh4: {
        marginHorizontal: 16
    },
    mh7: {
        marginHorizontal: 28
    },
    mh8: {
        marginHorizontal: 32
    },

    // Margin Vertical//
    mv1: {
        marginVertical: 4
    },
    mv2: {
        marginVertical: 8
    },
    mv3: {
        marginVertical: 12
    },
    mv4: {
        marginVertical: 16
    },
    mv8: {
        marginVertical: 32
    },

    // Margin Left //
    ml1: {
        marginLeft: 4
    },
    ml2: {
        marginLeft: 8
    },
    ml3: {
        marginLeft: 12
    },
    ml4: {
        marginLeft: 16
    },
    ml7: {
        marginLeft: 28
    },
    ml8: {
        marginLeft: 32
    },

    // Margin Rigth //
    mr1: {
        marginRight: 4
    },
    mr2: {
        marginRight: 8
    },
    mr3: {
        marginRight: 12
    },
    mr4: {
        marginRight: 16
    },
    mr8: {
        marginRight: 32
    },

    // Margin Top //
    mt1: {
        marginTop: 4
    },
    mt2: {
        marginTop: 8
    },
    mt3: {
        marginTop: 12
    },
    mt4: {
        marginTop: 16
    },
    mt8: {
        marginTop: 32
    },

    // Margin Bottom //
    mb1: {
        marginBottom: 4
    },
    mb2: {
        marginBottom: 8
    },
    mb3: {
        marginBottom: 12
    },
    mb4: {
        marginBottom: 16
    },
    mb5: {
        marginBottom: 20
    },
    mb6: {
        marginBottom: 24
    },
    mb7: {
        marginBottom: 28
    },
    mb8: {
        marginBottom: 32
    },
    
};

export const padding = {

    // padding Total //
    p1: {
        padding: 4
    },
    p2: {
        padding: 8
    },
    p3: {
        padding: 12
    },
    p4: {
        padding: 16
    },
    p5: {
        padding: 20
    },
    p6: {
        padding: 24
    },
    p8: {
        padding: 32
    },
    p9: {
        padding: 36
    },

    // padding Horizontal//
    ph1: {
        paddingHorizontal: 4
    },
    ph2: {
        paddingHorizontal: 8
    },
    ph3: {
        paddingHorizontal: 12
    },
    ph4: {
        paddingHorizontal: 16
    },
    ph5: {
        paddingHorizontal: 20
    },
    ph8: {
        paddingHorizontal: 32
    },

    // padding Vertical//
    pv1: {
        paddingVertical: 4
    },
    pv2: {
        paddingVertical: 8
    },
    pv3: {
        paddingVertical: 12
    },
    pv4: {
        paddingVertical: 16
    },
    pv5: {
        paddingVertical: 20
    },
    pv8: {
        paddingVertical: 32
    },
    pv9: {
        paddingVertical: 36
    },
    pv10: {
        paddingVertical: 40
    },

    // padding Left //
    pl1: {
        paddingLeft: 4
    },
    pl2: {
        paddingLeft: 8
    },
    pl3: {
        paddingLeft: 12
    },
    pl4: {
        paddingLeft: 16
    },
    pl5: {
        paddingLeft: 20
    },
    pl8: {
        paddingLeft: 32
    },

    // padding Rigth //
    pr1: {
        paddingRight: 4
    },
    pr2: {
        paddingRight: 8
    },
    pr3: {
        paddingRight: 12
    },
    pr4: {
        paddingRight: 16
    },
    pr5: {
        paddingRight: 20
    },
    pr8: {
        paddingRight: 32
    },
    pr9: {
        paddingRight: 36
    },
    pr11: {
        paddingRight: 44
    },

    // padding Top //
    pt1: {
        paddingTop: 4
    },
    pt2: {
        paddingTop: 8
    },
    pt3: {
        paddingTop: 12
    },
    pt4: {
        paddingTop: 16
    },
    pt5: {
        paddingTop: 20
    },
    pt8: {
        paddingTop: 32
    },
    pt15: {
        paddingTop: 60
    },
    pt16: {
        paddingTop: 64
    },
    pt17: {
        paddingTop: 68
    },

    // padding Bottom //
    pb1: {
        paddingBottom: 4
    },
    pb2: {
        paddingBottom: 8
    },
    pb3: {
        paddingBottom: 12
    },
    pb4: {
        paddingBottom: 16
    },
    pb5: {
        paddingBottom: 20
    },
    pb8: {
        paddingBottom: 32
    },
    
};