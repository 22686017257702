import React, {useState} from 'react';
import BlurPanel from '../Single/BlurPanel';
import Feedback from '../Single/Feedback';

// Start Component //
const FeedbackPanel = () => {
    const [showFeedback, setShowFeedback] = useState(true);
    
    return (
        <BlurPanel>
            {showFeedback?
                <Feedback/> : null
            }
        </BlurPanel>
    );
};

export default FeedbackPanel;
