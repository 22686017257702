import {xanoVerifyToken} from './Apis.js';
import secureLocalStorage from 'react-secure-storage';

// Get Token from url //
export const getParameter = (param) => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] === param) {
            return pair[1];
        }
    }
    return(false);
};

// Get Token from Backend //
export const getToken = async (navigation, magicToken) => {
    const xanoResponse = await xanoVerifyToken(magicToken);
    secureLocalStorage.setItem("authToken", xanoResponse.authToken);
    secureLocalStorage.setItem("deepToken", xanoResponse.deepGToken);
    secureLocalStorage.setItem("openToken", xanoResponse.openToken);
    secureLocalStorage.setItem("msToken", xanoResponse.msoftToken);
    secureLocalStorage.setItem("userEnabled", xanoResponse.user.enabled);
    secureLocalStorage.setItem("userId", xanoResponse.user.id);
    secureLocalStorage.setItem("userName", xanoResponse.user.name);
    secureLocalStorage.setItem("userEmail", xanoResponse.user.email);
    navigation.navigate("Uno");
};

// Convert Audio to base64 in chunks, then join //
export const toBase64 = async (audioFile) => {
    const response = await fetch(audioFile).then((r) => r.blob());
    // Chunkify //
    const chunkSize = 200 * 200;
    const size = response.size;
    const chunks = Math.ceil(size / chunkSize);
    let base64Data = '';
    for (let i = 0; i < chunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, size);
        const chunk = response.slice(start, end);
        const buffer = await chunk.arrayBuffer();
        const chunkBase64 = btoa(String.fromCharCode(...new Uint8Array(buffer)));
        base64Data += chunkBase64;
    };
    const audioData = "data:audio/webm;base64," + base64Data;
    return audioData;
};

// Convert an Audio response to a playable Url blob //
export const toBlob = async (response) => {
    const audioData = await response.blob();
    const blob = new Blob([audioData], {type: 'audio/mp3'});
    const url = URL.createObjectURL(blob);
    return url;
};

// Convert time passed into a string for reference //
const minute = 60 * 1000;
const hour = minute * 60;
const day = hour * 24;
const month = day * 30;
const year = month * 12;

export const timeElapsed = (time) => {
    const date = new Date().getTime();
    const elapsed = date - time; 

    if (elapsed < minute) {
        return Math.round(elapsed / 1000) + ' second(s)';
    } else if (elapsed < hour) {
        return Math.round(elapsed / minute) + ' minute(s)';
    } else if (elapsed < day) {
        return Math.round(elapsed / hour) + ' hour(s)';
    } else if (elapsed < month) {
        return Math.round(elapsed / day) + ' day(s)';
    } else if (elapsed < year) {
        return Math.round(elapsed / month) + ' month(s)';
    } else {
        return 'aproximately ' + Math.round(elapsed / year) + ' year(s)';
    }
};

// Pick a random option //
export const randomizer = (option1, option2, option3, option4) => {
    const randomNumber = Math.floor(Math.random() * 4);
    let pickedOption;
    if (randomNumber === 1) {
        pickedOption = option1
    } else if (randomNumber === 2) {
        pickedOption = option2
    } else if (randomNumber === 3) {
        pickedOption = option3
    } else {
        pickedOption = option4
    };
    return pickedOption;
};
