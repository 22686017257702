import React from 'react';
import {View, Text, Image} from 'react-native';
import {style} from '../../Styles/Style.js';
import MenuButton from '../Single/MenuButton.js';
import {ButtonUno} from '../Single/TalkButton.js';
import QuestionButton from '../Single/QuestionButton';

// Checkmark Icon //
const successIcon = require('../../assets/Images/UnoAccept.png');

// Start Component //
const Controls = (props) => {

    return (
        <View style={style.instructContainer}>
            <View style={style.verifiedView}>
                <Image
                    style={style.icon16}
                    source={props.verification? successIcon : null}/>
                <Text style={style.verifiedTitle}>{props.verification? "Email confirmed" : ""}</Text>
            </View>
            <View style={style.controlSection}>
                <MenuButton
                    menuOpen={props.menuOpen}
                    toggleMenuPanel={props.toggleMenuPanel}/>
                <View style={style.talkContainer}>
                    <ButtonUno 
                        isPlaying={props.isPlaying}
                        aborting={props.aborting}
                        press={props.press}
                        pressIn={props.pressIn}
                        pressOut={props.pressOut}/>
                </View>
                <QuestionButton
                    feedbackOpen={props.feedbackOpen}
                    toggleFeedbackPanel={props.toggleFeedbackPanel}/>
            </View>
        </View>
    );
};

export default Controls;
