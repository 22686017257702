import React from 'react';
import {View} from 'react-native';

// Start Component //
const Spacer = (props) => {
    
    return (
        <View style={{
            width: props.width, 
            height: props.height, 
            backgroundColor: props.color, 
            marginTop: props.spaceTop, 
            marginBottom: props.spaceBelow
        }}/>
    );
};

export default Spacer;
