import React from 'react';
import {Text, Image, Pressable} from 'react-native';
import {style} from '../../Styles/Style.js';

// Talk Button Icons //
const recordImg = require('../../assets/Images/UnoTalk.png');
const sendImg = require('../../assets/Images/UnoSend.png');
const stopImg = require('../../assets/Images/UnoStop.png');

// Start Component //
const ButtonContent = (props) => {

    return (
        <Pressable 
            onLongPress={props.pressIn}
            delayLongPress={200}
            onPressOut={props.pressOut}
            delayPressOut={100}
            onPress={props.aborting}
            style={props.containerStyle}>
            <Image 
                style={props.imageStyle} 
                source={props.isPlaying? stopImg : (props.press? sendImg : recordImg)}
            />
            <Text 
                style={props.textStyle}>
                {props.isPlaying? "Stop answering" : (props.press? "Release to Send" : "Hold & Talk")}
            </Text>
        </Pressable>
        );
};

// Start Export Component //
export const ButtonWelcome = (props) => {
    return (
        <ButtonContent
            isPlaying={props.isPlaying}
            aborting={props.aborting}
            press={props.press}
            pressIn={props.pressIn} 
            pressOut={props.pressOut} 
            containerStyle={style.buttonWelcomeContainer}
            imageStyle={style.talkMedium}
            textStyle={style.buttonWelcomeText}
        />
    );
};

// Start Export Component //
export const ButtonUno = (props) => {
    return (
        <ButtonContent
            isPlaying={props.isPlaying}
            aborting={props.aborting}
            press={props.press} 
            pressIn={props.pressIn} 
            pressOut={props.pressOut} 
            containerStyle={style.buttonUnoContainer}
            imageStyle={style.talkSmall}
            textStyle={style.buttonUnoText}
        />
    );
};
