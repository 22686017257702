import React from 'react';
import {View, Text} from 'react-native';
import {style} from '../../Styles/Style.js';

// Start Component //
const Question = (props) => {

    return (
        <View style={style.questionSection}>
            <Text style={style.questionText}>{props.question}</Text>
        </View>
    );
};

export default Question;
