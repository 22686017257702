export const assets = {
    waitingAudio: [
        require('../assets/Audios/unoWaiting01.mp3'),
        require('../assets/Audios/unoWaiting02.mp3'),
        require('../assets/Audios/unoWaiting03.mp3'),
        require('../assets/Audios/unoWaiting04.mp3'),
        require('../assets/Audios/unoWaiting05.mp3'),
        require('../assets/Audios/unoWaiting06.mp3'),
        require('../assets/Audios/unoWaiting07.mp3'),
    ]
};
